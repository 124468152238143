import React, { useState, useEffect } from 'react';
import {
  FormControlLabel,
  FormGroup,
  Switch,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  TextField,
  FormHelperText,
  Button,
  Autocomplete,
} from '@mui/material';
import Select from '@mui/material/Select';
import { retailerServiceTypeOptions } from './retailerServiceTypeOptions';
import { AMAZON_SELLING_PARTNER_API_AUTH_URL, AMAZON_ADVERTISING_API_AUTH_URL } from '../../../api-config';
import CircularProgress from '@mui/material/CircularProgress';
import { purchaseNewTwilioNumber } from '../../../Utilities/ForemanApiClient';
import { styles } from './Credentials.styles';
import { AmazonRetailer } from './ConditionalInputs/AmazonRetailer';
import { KrogerRetailer } from './ConditionalInputs/KrogerRetailer';
import { WalmartUSRetailer } from './ConditionalInputs/WalmartUSRetailer';
import { WalmartUSRetailLinkMFA } from './ConditionalInputs/WalmartUSRetailLinkMFA';
import { WalmartUSLuminateAPI } from './ConditionalInputs/WalmartUSLuminateAPI';
import { TargetRetailer } from './ConditionalInputs/TargetRetailer';

export const CredentialForm = ({
  credential,
  mode,
  jobGroups,
  retailers,
  childClient,
  modifiedCredential,
  setModifiedCredential,
  amazonSPAPIMarketplaceOptions,
  setSelectedRetailerServiceType,
  selectedRetailerServiceType,
  selectedAmazonAccountType,
  setSelectedAmazonAccountType,
  selectedWalmartAdsLocation,
  setSelectedWalmartAdsLocation,
  disableButtons,
  setDisableButtons,
}) => {
  const [selectedRetailer, setSelectedRetailer] = useState({});
  const [selectedAmazonSPAPIMarketplaces, setSelectedAmazonSPAPIMarketplaces] = useState([]);
  const [amazonSPAPIMarketplaceOptionsForRegion, setAmazonSPAPIMarketplaceOptionsForRegion] = useState([]);
  const [selectedJobGroup, setSelectedJobGroup] = useState({});
  const [isTwilioNumberValid, setIsTwilioNumberValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isClientNumberValid, setIsClientNumberValid] = useState(true);
  const [showError, setShowError] = useState(false);
  const [isValidEmailFormat, setIsValidEmailFormat] = useState(false);

  const amazonAccountTypeOptions = [
    { value: 'seller', label: 'Seller Central' },
    { value: 'vendor', label: 'Vendor Central' },
  ];

  const walmartAdsLocationOptions = [
    { value: 'Offsite', label: 'Offsite' },
    { value: 'Onsite', label: 'Onsite' },
    { value: '', label: 'N/A' },
  ];

  useEffect(() => {
    const jobGroupId = credential && credential.jobGroupId;
    const jobGroup = jobGroupId && jobGroups.find((group) => group.id == jobGroupId);
    const jobGroupObj = jobGroup ? { value: jobGroupId, label: jobGroup.name } : {};

    setSelectedJobGroup(jobGroupObj);
  }, [credential, jobGroups]);

  useEffect(() => {
    if (childClient) {
      const foundRetailer = retailers.find((retailer) => retailer.id == childClient.value.retailerId);

      foundRetailer && setSelectedRetailer(foundRetailer?.name);

      if (credential && credential.retailerServiceType && credential.retailerServiceTypeDescription) {
        selectedRetailerServiceTypeOnChange(credential.retailerServiceType, credential.retailerServiceTypeDescription);
      } else {
        const firstAvailableRetailerServiceType = mapSelectedRetailerToRetailerServiceTypeOptions()[0];
        selectedRetailerServiceTypeOnChange(firstAvailableRetailerServiceType.value, firstAvailableRetailerServiceType.label);
      }
    }
  }, []);

  const clientNumberOnChange = (e) => {
    const regex = RegExp('^[+][1][0-9]{10}$');
    const inputValue = e.target.value;
    setIsClientNumberValid(inputValue === '' || regex.test(inputValue));

    setModifiedCredential({ ...modifiedCredential, clientNumber: inputValue });
  };

  const purchaseOnClick = async () => {
    setIsLoading(true);
    const newPhoneNumber = await purchaseNewTwilioNumber(credential.id);
    setModifiedCredential({ ...modifiedCredential, twilioNumber: newPhoneNumber });
    setIsLoading(false);
  };

  const jobGroupSelectOnChange = (value) => {
    const selectedGroup = jobGroups.find((group) => group.id === value?.value);
    setSelectedJobGroup({ value: selectedGroup?.id, label: selectedGroup?.name });
    const changedCredential = { ...modifiedCredential, jobGroupId: value?.value ? value.value : value };

    setModifiedCredential(changedCredential);
  };

  const validateAndSaveTwilioNumber = (e) => {
    const regex = RegExp('^[+][1][0-9]{10}$');
    const inputValue = e.target.value;
    setIsTwilioNumberValid(inputValue === '' || regex.test(inputValue));

    setModifiedCredential({ ...modifiedCredential, twilioNumber: inputValue });
  };

  const mapSelectedRetailerToRetailerServiceTypeOptions = () => {
    return retailerServiceTypeOptions[selectedRetailer] || retailerServiceTypeOptions['default'];
  };

  const selectedRetailerServiceTypeOnChange = (value, label) => {
    setSelectedRetailerServiceType({ value: value, label: label });

    if (modifiedCredential && Object.keys(modifiedCredential).length > 0) {
      setModifiedCredential({
        ...modifiedCredential,
        retailerServiceType: value,
      });
    }
  };

  const onUserNameChange = (e) => {
    setModifiedCredential({ ...modifiedCredential, username: e.target.value });
    if (!e.target.value || e.target.value === '') {
      setDisableButtons(true);
    } else {
      setDisableButtons(false);
    }
  };

  return (
    <div style={{ display: 'grid' }}>
      {mode === 'edit' && (
        <>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item xs={2}>
              <Typography>{modifiedCredential.isActive ? 'Pause' : 'Unpause'}</Typography>
            </Grid>
            <Grid item xs={10}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={modifiedCredential.isActive}
                      onChange={() =>
                        setModifiedCredential({
                          ...modifiedCredential,
                          isActive: !modifiedCredential.isActive,
                        })
                      }
                    />
                  }
                  label=""
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item xs={2}>
              <Typography>Id</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography>{credential.id}</Typography>
            </Grid>
          </Grid>
        </>
      )}
      <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
        <Grid item sm={2}>
          <Typography>Client Number</Typography>
        </Grid>
        <Grid item sm={10}>
          <FormControl fullWidth>
            <Typography>{credential && credential.childClientId}</Typography>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
        <Grid item sm={2}>
          <Typography>Retailer Service Type</Typography>
        </Grid>
        <Grid item sm={10}>
          <FormControl fullWidth>
            <Select
              sx={styles.Inputs}
              className="react-select primary"
              variant="outlined"
              placeholder="Retailer Service Type (required)"
              value={selectedRetailerServiceType.value || null}
              displayEmpty
              onChange={(event) => {
                const selectedOption = mapSelectedRetailerToRetailerServiceTypeOptions().find(
                  (option) => option.value === event.target.value
                );
                selectedRetailerServiceTypeOnChange(event.target.value, selectedOption ? selectedOption.label : '');
              }}
            >
              {mapSelectedRetailerToRetailerServiceTypeOptions().map((option) => (
                <MenuItem key={option.value} value={option.value} selected={option.value === selectedRetailerServiceType.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {selectedRetailer == 'Amazon' && (
        <AmazonRetailer
          selectedRetailerServiceType={selectedRetailerServiceType}
          modifiedCredential={modifiedCredential}
          setModifiedCredential={setModifiedCredential}
          AMAZON_ADVERTISING_API_AUTH_URL={AMAZON_ADVERTISING_API_AUTH_URL}
          AMAZON_SELLING_PARTNER_API_AUTH_URL={AMAZON_SELLING_PARTNER_API_AUTH_URL}
          credential={credential}
          setSelectedAmazonAccountType={setSelectedAmazonAccountType}
          selectedAmazonAccountType={selectedAmazonAccountType}
          amazonAccountTypeOptions={amazonAccountTypeOptions}
          selectedAmazonSPAPIMarketplaces={selectedAmazonSPAPIMarketplaces}
          setSelectedAmazonSPAPIMarketplaces={setSelectedAmazonSPAPIMarketplaces}
          amazonSPAPIMarketplaceOptionsForRegion={amazonSPAPIMarketplaceOptionsForRegion}
          setAmazonSPAPIMarketplaceOptionsForRegion={setAmazonSPAPIMarketplaceOptionsForRegion}
          amazonSPAPIMarketplaceOptions={amazonSPAPIMarketplaceOptions}
          childClient={childClient}
        />
      )}
      {selectedRetailerServiceType.value !== 19 &&
        selectedRetailerServiceType.value !== 18 &&
        selectedRetailerServiceType.value !== 16 &&
        selectedRetailerServiceType.value !== 15 &&
        selectedRetailerServiceType.value !== 17 &&
        selectedRetailerServiceType.value !== 26 &&
        selectedRetailerServiceType.value !== 25 && (
          <>
            <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
              <Grid item sm={2}>
                <Typography>Username</Typography>
              </Grid>
              <Grid item sm={10}>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    autoComplete="off"
                    value={(modifiedCredential && modifiedCredential.username) || ''}
                    onChange={(e) => onUserNameChange(e)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
              <Grid item sm={2}>
                <Typography>Password</Typography>
              </Grid>
              <Grid item sm={10}>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    autoComplete="off"
                    value={(modifiedCredential && modifiedCredential.password) || ''}
                    onChange={(e) => setModifiedCredential({ ...modifiedCredential, password: e.target.value })}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}
      <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
        <Grid item sm={2}>
          <Typography>Notes</Typography>
        </Grid>
        <Grid item sm={10}>
          <FormControl fullWidth>
            <TextField
              multiline
              rows={4}
              autoComplete="off"
              value={(modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.Notes) || ''}
              onChange={(e) =>
                setModifiedCredential({
                  ...modifiedCredential,
                  parameterValues: { ...modifiedCredential.parameterValues, Notes: e.target.value },
                })
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      {selectedRetailerServiceType.value !== 18 && jobGroups && (
        <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
          <Grid item sm={2}>
            <Typography>Job Group</Typography>
          </Grid>
          <Grid item sm={10}>
            <FormControl fullWidth></FormControl>
            <Autocomplete
              sx={styles.Inputs}
              id="combo-box-demo"
              getOptionLabel={(option) => option.label || ''}
              value={selectedJobGroup || null}
              options={jobGroups.map((jobGroup) => ({
                value: jobGroup.id,
                label: jobGroup.name,
              }))}
              onChange={(event, value) => {
                jobGroupSelectOnChange(value);
              }}
              isOptionEqualToValue={(option, value) => option.value === value}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
        </Grid>
      )}
      {mode === 'edit' && (
        <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
          <Grid item sm={2}>
            <Typography>Password Is Fresh</Typography>
          </Grid>
          <Grid item sm={10}>
            <FormControlLabel
              control={
                <Switch
                  checked={
                    (modifiedCredential.isPasswordFresh &&
                      (modifiedCredential.isPasswordFresh === 'true' || modifiedCredential.isPasswordFresh === true)) ||
                    false
                  }
                  onChange={() =>
                    setModifiedCredential({
                      ...modifiedCredential,
                      isPasswordFresh: !(
                        modifiedCredential.isPasswordFresh === 'true' || modifiedCredential.isPasswordFresh === true
                      ),
                    })
                  }
                  color="primary"
                />
              }
            />
          </Grid>
        </Grid>
      )}
      {selectedRetailer === 'Kroger' && (
        <KrogerRetailer
          selectedRetailerServiceType={selectedRetailerServiceType}
          modifiedCredential={modifiedCredential}
          setModifiedCredential={setModifiedCredential}
        />
      )}
      {selectedRetailer === 'Walmart US' && (
        <WalmartUSRetailer
          selectedRetailerServiceType={selectedRetailerServiceType}
          modifiedCredential={modifiedCredential}
          setModifiedCredential={setModifiedCredential}
          setSelectedWalmartAdsLocation={setSelectedWalmartAdsLocation}
          selectedWalmartAdsLocation={selectedWalmartAdsLocation}
          walmartAdsLocationOptions={walmartAdsLocationOptions}
          isClientNumberValid={isClientNumberValid}
          clientNumberOnChange={clientNumberOnChange}
          FormHelperText={FormHelperText}
          isTwilioNumberValid={isTwilioNumberValid}
          validateAndSaveTwilioNumber={validateAndSaveTwilioNumber}
          mode={mode}
          isLoading={isLoading}
          purchaseOnClick={purchaseOnClick}
          setDisableButtons={setDisableButtons}
        />
      )}
      {(selectedRetailerServiceType.value === 21 || selectedRetailerServiceType.value === 28) && (
        <WalmartUSRetailLinkMFA
          selectedRetailerServiceType={selectedRetailerServiceType}
          modifiedCredential={modifiedCredential}
          setModifiedCredential={setModifiedCredential}
          isClientNumberValid={isClientNumberValid}
          clientNumberOnChange={clientNumberOnChange}
          FormHelperText={FormHelperText}
          isTwilioNumberValid={isTwilioNumberValid}
          validateAndSaveTwilioNumber={validateAndSaveTwilioNumber}
          mode={mode}
          isLoading={isLoading}
          purchaseOnClick={purchaseOnClick}
        />
      )}
      {selectedRetailerServiceType.value === 25 && (
        <WalmartUSLuminateAPI
          selectedRetailerServiceType={selectedRetailerServiceType}
          modifiedCredential={modifiedCredential}
          setModifiedCredential={setModifiedCredential}
          FormHelperText={FormHelperText}
          mode={mode}
          isLoading={isLoading}
        />
      )}
      {selectedRetailer === 'Target' && (
        <TargetRetailer
          selectedRetailerServiceType={selectedRetailerServiceType}
          modifiedCredential={modifiedCredential}
          setModifiedCredential={setModifiedCredential}
        />
      )}
      {selectedRetailerServiceType.value === 12 && (
        <>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Name for MFA Forwarding</Typography>
            </Grid>
            <Grid item sm={4}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential &&
                      modifiedCredential.parameterValues &&
                      modifiedCredential.parameterValues.MFAForwardingName) ||
                    ''
                  }
                  onChange={(event) => {
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: {
                        ...modifiedCredential.parameterValues,
                        MFAForwardingName: event.target.value,
                      },
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Email for MFA Forwarding</Typography>
            </Grid>
            <Grid item sm={4}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential &&
                      modifiedCredential.parameterValues &&
                      modifiedCredential.parameterValues.MFAForwardingEmail) ||
                    ''
                  }
                  onChange={(event) => {
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: {
                        ...modifiedCredential.parameterValues,
                        MFAForwardingEmail: event.target.value,
                      },
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Twilio Telephone Number</Typography>
            </Grid>
            <Grid item sm={4}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  error={!isTwilioNumberValid}
                  autoComplete="off"
                  value={(modifiedCredential && modifiedCredential.twilioNumber) || ''}
                  onChange={validateAndSaveTwilioNumber}
                />
                {!isTwilioNumberValid && <FormHelperText error>US number format is required (ex: +11234567891).</FormHelperText>}
              </FormControl>
            </Grid>
            {mode === 'edit' && modifiedCredential && !modifiedCredential.twilioNumber && (
              <Grid item sm={2}>
                {isLoading ? (
                  <CircularProgress className="form-button-spinner" color="primary" />
                ) : (
                  <Button
                    color="info"
                    style={{ marginLeft: '1em', marginTop: '0', marginBottom: '0' }}
                    onClick={async () => await purchaseOnClick()}
                  >
                    PURCHASE
                  </Button>
                )}
              </Grid>
            )}
            <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
              <Grid item sm={2}>
                <Typography>Should Forward MFA To Email</Typography>
              </Grid>
              <Grid item sm={10}>
                <FormControl fullWidth>
                  <Switch
                    checked={modifiedCredential.shouldForwardMFAToEmail}
                    onChange={() =>
                      setModifiedCredential({
                        ...modifiedCredential,
                        shouldForwardMFAToEmail: !modifiedCredential.shouldForwardMFAToEmail,
                      })
                    }
                    color="primary"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {selectedRetailerServiceType.value == 30 && (
        <>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Bot Token</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  value={(modifiedCredential && modifiedCredential.authToken) || ''}
                  onChange={(event) => {
                    setModifiedCredential({ ...modifiedCredential, authToken: event.target.value });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>External Email</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential &&
                      modifiedCredential.parameterValues &&
                      modifiedCredential.parameterValues.ExternalEmail) ||
                    ''
                  }
                  onChange={(event) => {
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: {
                        ...modifiedCredential.parameterValues,
                        ExternalEmail: event.target.value,
                      },
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Retail Link Admin Email</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential &&
                      modifiedCredential.parameterValues &&
                      modifiedCredential.parameterValues.RetailLinkAdminEmail) ||
                    ''
                  }
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const hasSemicolon = inputValue.includes(';');
                    const isValidEmail = /^$|^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue);

                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: {
                        ...modifiedCredential.parameterValues,
                        RetailLinkAdminEmail: inputValue,
                      },
                    });

                    const showError = hasSemicolon || !isValidEmail;

                    setShowError(showError);
                    setIsValidEmailFormat(isValidEmail);
                    setDisableButtons(showError);
                  }}
                />
                {showError && (
                  <p style={{ color: '#b71c1c' }}>
                    {!isValidEmailFormat
                      ? 'Invalid email format. Please enter a valid email address.'
                      : 'The RL Admin email field cannot contain multiple email addresses.'}
                  </p>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Enable Forwarding To Client</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <Switch
                  checked={modifiedCredential.isForwardToExternalEnabled}
                  onChange={() =>
                    setModifiedCredential({
                      ...modifiedCredential,
                      isForwardToExternalEnabled: !modifiedCredential.isForwardToExternalEnabled,
                    })
                  }
                  color="primary"
                />
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
      {selectedRetailerServiceType.value === 20 && (
        <>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Advertiser Id</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential &&
                      modifiedCredential.parameterValues &&
                      modifiedCredential.parameterValues.AdvertiserId) ||
                    ''
                  }
                  onChange={(event) => {
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: {
                        ...modifiedCredential.parameterValues,
                        AdvertiserId: event.target.value,
                      },
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
      {selectedRetailerServiceType.value === 18 && (
        <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
          <Grid item xs={2}>
            <label>Profile Id</label>
          </Grid>
          <Grid item sm={10}>
            <FormControl fullWidth>
              <TextField
                type="text"
                autoComplete="off"
                value={
                  (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.ProfileId) || ''
                }
                onChange={(e) => {
                  setModifiedCredential({
                    ...modifiedCredential,
                    parameterValues: { ...modifiedCredential.parameterValues, ProfileId: e.target.value },
                  });
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
      {selectedRetailerServiceType.value === 24 && (
        <FormControl fullWidth>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Criteo Account Id</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential &&
                      modifiedCredential?.parameterValues &&
                      modifiedCredential?.parameterValues.CriteoAccountId) ||
                    ''
                  }
                  onChange={(e) => {
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: { ...modifiedCredential?.parameterValues, CriteoAccountId: e.target.value },
                    });
                  }}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
          </Grid>
        </FormControl>
      )}
      {selectedRetailerServiceType.value === 29 && (
        <FormControl fullWidth>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Instacart Client UID</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential &&
                      modifiedCredential?.parameterValues &&
                      modifiedCredential?.parameterValues.InstacartClientUID) ||
                    ''
                  }
                  onChange={(e) => {
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: {
                        ...modifiedCredential?.parameterValues,
                        InstacartClientUID: e.target.value,
                      },
                    });
                  }}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
              <Grid item sm={2}>
                <Typography>Instacart Client Secret</Typography>
              </Grid>
              <Grid item sm={10}>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    autoComplete="off"
                    value={
                      (modifiedCredential &&
                        modifiedCredential?.parameterValues &&
                        modifiedCredential?.parameterValues.InstacartClientSecret) ||
                      ''
                    }
                    onChange={(e) => {
                      setModifiedCredential({
                        ...modifiedCredential,
                        parameterValues: {
                          ...modifiedCredential?.parameterValues,
                          InstacartClientSecret: e.target.value,
                        },
                      });
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
              <Grid item sm={2}>
                <Typography>Instacart RefreshToken</Typography>
              </Grid>
              <Grid item sm={10}>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    autoComplete="off"
                    value={
                      (modifiedCredential &&
                        modifiedCredential?.parameterValues &&
                        modifiedCredential?.parameterValues.InstacartRefreshToken) ||
                      ''
                    }
                    onChange={(e) => {
                      setModifiedCredential({
                        ...modifiedCredential,
                        parameterValues: {
                          ...modifiedCredential?.parameterValues,
                          InstacartRefreshToken: e.target.value,
                        },
                      });
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      )}
    </div>
  );
};